@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --brand-3: #3466ff;
  --brand-2: #5079f5;
  --brand-1: #f3f6ff;
  
  --blue-3: #3466ff;
  --blue-2: #5079f5;
  --blue-1: #f3f6ff;

  --green-3: #57bd97;
  --green-2: #7cbda4;
  --green-1: #f2f8f6;

  --red-3: #d14443;
  --red-2: #de7979;
  --red-1: #fcf3f4;

  --black: #13161a;
  --font: Fira Sans, arial, sans-serif;
  --border-radius: 0;
}

.App {
  margin: 0 auto;
  height: 100%;
}

.wrapper {
  max-width: 768px;
  padding: 2em;
}

a {
  color: #5079f5;
  color: var(--brand-2);
}

html,
body {
  height: 100%;
}

.App.hhc {
  --black: #000000;
  --brand-3: #F04600;
  --brand-2: #F04600;
  --brand-1: #f38b5f;
}

.App.rmv {
  --black: #0D0D0D;
  --brand-3: #072556;
  --brand-2: #0054A5;
  --brand-1: #5aa3e7;
  --border-radius: 5px;
}

.App.usvf {
  --black: #333333;
  --brand-3: #01214a;
  --brand-2: #045392;
  --brand-1: #045392;
  --border-radius: 5px;
}

.App.rtv {
  --black: #000000;
  --brand-3: #000000;
  --brand-2: #000000;
  --brand-1: #000000;
  --border-radius: 0px;
}

h1 {
  font-weight: 800;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.385rem;
}

label {
  color: #13161a;
  color: var(--black);
  font-size: 1.25rem;
  font-weight: 700;
  display: block;
  margin-bottom: .2875rem;
}

.float-right {
  float: right;
}

blockquote {
  margin-left: 0;
  margin-right: 1.6875rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 1.37109rem;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.6875rem;
  font-size: 1.1487rem;
  line-height: 1.6875rem;
  color: hsla(0,0%,0%,0.59);
  border-left: 0.31641rem solid #5079f5;
}

.small {
  font-size: .75rem;
  font-weight: 400;
  color: #13161a;
  color: var(--black);
  margin: 0.3875rem 0 0 0;
  line-height: 1em;
}

/* ul {
  margin-left: -20px;
} */


.lead {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.2875rem;
  transition: opacity 0.55s;
  opacity: 1;
}

.hide {
  margin-bottom: 0;
  opacity: 0;
  height: 0;
  pointer-events: none;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.975em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0;
  border-radius: var(--border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* reactSelect */
.form-group .reactSelect__control {
  border-radius: 0;
  border-radius: var(--border-radius);
  font-weight: 600;
  box-shadow: none;
}

.form-group .reactSelect__menu {
  margin: 0.125rem auto;
}

.form-group .reactSelect__option {
  font-weight: 600;
  cursor: pointer;
}

.form-group .reactSelect__option--is-selected {
  background-color: #3466ff;
  background-color: var(--brand-3);
}

.form-group .reactSelect__option--is-focused {
  color: rgba(255, 255, 255, 0.8);
  background-color: #5079f5;
  background-color: var(--brand-2);
}

.form-group .reactSelect__group {
  padding: 0;
}

.form-group .reactSelect__menu-portal {
  border: 1px solid darkblue;
}

/* btn */
.btn {
  display: inline-block;
  font-weight: 800;
  color: #495057;
  background-color: #ececec;
  border-color: #ececec;
  border-radius: 0;
  border-radius: var(--border-radius);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .475rem .875rem;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: color .225s ease-in-out,background-color .225s ease-in-out,border-color .225s ease-in-out,box-shadow .225s ease-in-out;
}

label.btn {
  margin-bottom: 0;
}

.btn-check {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
  margin: 0 0 0 0;
}

.btn-check:hover + .btn {
  color: rgba(255, 255, 255, 0.9);
  background-color: #5079f5;
  background-color: var(--brand-2);
}

.form-group .radio-wrapper:first-of-type {
  margin: 0 0.5rem 0.5rem 0;
}

.radio-wrapper {
  position: relative;
  margin: 0 0.5rem 0.5rem 0;
  display: inline-block;
}

.radio-wrapper input:checked + .btn {
  color: #fff;
  background-color: #5079f5;
  background-color: var(--brand-2);
  border-color: #5079f5;
  border-color: var(--brand-2);
}
.radio-options {
  display: flex;
  flex-direction: column;
}

.About img {
  border-radius: 10px;
  margin: 2em 0 0;
}

/* results */
.result-wrapper {
  transition: opacity 0.15s;
  position: relative;
  padding: 1.75rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
  border-radius: var(--border-radius);
}

.result-wrapper span {
  width: 100%;
}

.result-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-wrapper h2 {
  font-weight: 800;
  font-size: 1.875rem;
  margin-top: 0;
  width: 100%;
}

.result-wrapper span .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-wrapper-green * {
  color: #57bd97;
  color: var(--green-3);
}
.result-wrapper-green {
  
  background-color: #f2f8f6;
  
  background-color: var(--green-1);
  border-color: #7cbda4;
  border-color: var(--green-2);
}

.result-wrapper-red * {
  color: #d14443;
  color: var(--red-3);
}
.result-wrapper-red {
  
  background-color: #fcf3f4;
  
  background-color: var(--red-1);
  border-color: #de7979;
  border-color: var(--red-2);
}

.result-wrapper-blue * {
  color: #3466ff;
  color: var(--blue-3);
}
.result-wrapper-blue {
  
  background-color: #f3f6ff;
  
  background-color: var(--blue-1);
  border-color: #5079f5;
  border-color: var(--blue-2);
}

.result-wrapper-green .btn {
  color: #ffffff;
  text-decoration: none;
  background-color: #57bd97;
  background-color: var(--green-3);
  border-color: #7cbda4;
  border-color: var(--green-2);
}
.result-wrapper-green .btn:hover {
  background-color: #7cbda4;
  background-color: var(--green-2);
}

.result-wrapper-red .btn {
  color: #ffffff;
  text-decoration: none;
  background-color: #d14443;
  background-color: var(--red-3);
  border-color: #de7979;
  border-color: var(--red-2);
}
.result-wrapper-red .btn:hover {
  background-color: #de7979;
  background-color: var(--red-2);
}

.result-wrapper-blue .btn {
  color: #ffffff;
  text-decoration: none;
  background-color: #3466ff;
  background-color: var(--blue-3);
  border-color: #5079f5;
  border-color: var(--blue-2);
}
.result-wrapper-blue .btn:hover {
  background-color: #5079f5;
  background-color: var(--blue-2);
}

.result-wrapper-green h2:before {
  display: inline-flex;
  content: ' ';
  background: url(/static/media/check-circle.20fe9719.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  height: 18px;
  width: 5%;
}

.result-wrapper-red h2:before {
  display: inline-flex;
  content: ' ';
  background: url(/static/media/x-circle.29b06124.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  height: 18px;
  width: 5%;
}

.result-wrapper-blue h2:before {
  display: inline-flex;
  content: ' ';
  background: url(/static/media/alert-circle.b86092bf.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  height: 18px;
  width: 5%;
}

.result-wrapper-green a {
  font-weight: 700;
  color: #57bd97;
  color: var(--green-3);
}

.result-wrapper-blue a {
  font-weight: 700;
  color: #3466ff;
  color: var(--blue-3);
}

.result-wrapper-red a {
  font-weight: 700;
  color: #d14443;
  color: var(--red-3);
}

@media only screen and (max-width: 600px) {
  .result-wrapper {
    padding: 1rem;
  }
  .result-wrapper h2:before {
    width: 8%;
  }
}

